

	.icon {
		@include icon;
		border-bottom: none;
		position: relative;

		> .label {
			display: none;
		}

		&.prefix {
		}

		&.suffix {
			&:before {
				float: right;
			}
		}
	}