

	section, article {
		&.special {
			text-align: center;
		}
	}

	header {
		p {
			font-size: 1.25em;
			font-weight: normal;
			line-height: 2;
			margin-top: -1em;
			text-align: justify;
		}
	}