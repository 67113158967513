

	.blurb {
		h2 {
			font-size: 0.8em;
			margin: 0 0 (_size(element-margin) * 0.75) 0;
		}

		h3 {
			font-size: 0.7em;
		}

		p {
			font-size: 0.9em;
		}
	}